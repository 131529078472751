<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      :style="{'padding-bottom': matrix_height + 'px'}"
      :no-gutters="!$vuetify.breakpoint.mobile"
    >
      <v-col
        md="4"
        lg="6"
        class="pr-3"
      >
        <v-card
          :loading="loading.lab_result"
          min-height="500"
        >
          <v-card-title class="d-flex justify-space-between">
            <div>
              {{ $tc('Lab Result', 1) }} <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span>
              <method-chip
                :method="lab_result.method"
                outlined
                class="ml-2 mt-n1"
              />
            </div>
            <client-icon
              v-if="lab_result.client && $auth.check({ clients: ['*', 'view','edit'] })"
              :key="lab_result.client.uuid"
              :icon="lab_result.client.icon"
              :name="lab_result.client.name"
            />
          </v-card-title>
          <status-notes
            v-if="['needs review', 'contact lab', 'tech support'].includes(lab_result.status)"
            :status-text="lab_result.status"
            :statuses="lab_result.statuses"
          />
          <v-card-text>
            <lab-result
              :lab-result="lab_result"
              :loading="loading.lab_result"
              @organism-updated="fetchLabResult()"
              @allergen-updated="fetchLabResult()"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="8"
        lg="6"
      >
        <v-card
          :key="`interpretation-${$route.params.uuid}`"
          :loading="loading.lab_result"
        >
          <v-card-title>
            {{ $t('interpretation') | capitalize }}
            <v-spacer />
            <template v-if="lab_result.formulary && lab_result.formulary.id != 1">
              <v-chip left>
                <v-icon
                  small
                  class="me-1"
                >
                  fa-light fa-prescription-bottle-pill
                </v-icon>
                {{ lab_result.formulary.name }}
              </v-chip>
            </template>
            <record-lock
              :uuid="$route.params.uuid"
              :record-lock="lab_result.record_lock"
              @update:lock="lab_result.record_lock = $event"
            />
          </v-card-title>
          <v-card-text>
            <v-form
              ref="interpretation"
              v-model="valid"
              lazy-validation
            >
              <v-card
                outlined
                class="mb-4"
              >
                <v-card-title class="pt-2">
                  <v-img
                    src="/img/onechoice-logo-name.svg"
                    :contain="true"
                    :max-width="100"
                    :max-height="50"
                  />
                  <v-spacer />
                  <v-switch
                    v-model="interpretation.drugs_indicated"
                    :label="$t('Treatment indicated')"
                    dense
                    xcolor="primary"
                    class="mt-1 mb-n5 mr-3 pa-0"
                    xclick="!interpretation.drugs.length ? addDrug() : null"
                    @click="interpretation.impression_duration = !interpretation.impression_duration ? 'Treatment may not be indicated' : interpretation.impression_duration"
                  />
                  <v-divider
                    vertical
                    inset
                  />
                  <smart-impression
                    :uuid="$route.params.uuid"
                    :organisms="detected_organisms"
                    :resistance-genes="lab_result.resistance_genes"
                    :relevant-resistance="relevantResistance"
                    :diagnoses="lab_result.diagnoses"
                    :allergies="lab_result.allergies"
                    :pediatric="pediatric"
                    :threshold="smart_threshold"
                    :preload="lab_result && !lab_result.negative && !unclassified_data && !interpretation.impression_duration"
                    :filter-gender="lab_result.sample_source == 'urine' ? lab_result.patient.gender : false"
                    xauto-populate="lab_result.status != 'unclassified data'"
                    @impression-selected="updateImpression($event)"
                  />
                </v-card-title>
                <v-card-text class="py-0">
                  <template v-if="interpretation.drugs_indicated">
                    <drug-regimen
                      :regimen="interpretation.one_choice || undefined"
                      :uuid="$route.params.uuid"
                      :organisms="detected_organisms"
                      :resistance-genes="detected_resistance"
                      :relevant-resistance="relevantResistance"
                      :diagnoses="lab_result.diagnoses"
                      :allergies="lab_result.allergies"
                      :pediatric="pediatric"
                      :source="lab_result.sample_source"
                      :gender="lab_result.patient.gender"
                      :pregnant="lab_result.pregnant"
                      :formulary="lab_result.formulary"
                      :method="lab_result.method"
                      xreadonly="!$auth.check({'interpretations': ['*', 'edit']})"
                      :value.sync="interpretation.one_choice"
                    />
                    <!-- <v-row
                      v-for="prescription, drugIndex in interpretation.drugs"
                      :key="drugIndex"
                    >
                      <v-col
                        cols="5"
                      >
                        <drug-picker
                          v-if="lab_result && interpretation.drugs"
                          :key="`drug-picker-${drugIndex}`"
                          v-model="prescription.drug"
                          :readonly="!$auth.check({'interpretations': ['*', 'edit']})"
                          :value="prescription.drug"
                          :allowed-drugs="detected_organisms.flatMap(x => x.effective_drugs).filter(x => !!x).map(x => x.id)"
                          :label="$tc('drug', 1) | capitalize"
                          :source="lab_result.sample_source"
                          :resistance="detected_resistance.flatMap(x => x.resistance).filter((v, i, a) => a.findIndex(x => x.name === v.name) === i).filter(x => relevantResistance.includes(x.name))"
                          :allergies="lab_result.allergies"
                          :pediatric="pediatric"
                          @input="prescription.dosage = ''; prescription.dosage = defaultDosage(prescription)"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          xv-model.trim="prescription.dosage"
                          label="Dosage"
                          :readonly="!$auth.check({'interpretations': ['*', 'edit']}) || (pediatric && !$auth.check({'admin': ['beta']}))"
                          :disabled="!prescription.drug"
                          :rules="[v => !!v || 'Please enter a valid dosage']"
                          :value="defaultDosage(prescription)"
                          class="py-0 my-0"
                          @change="v => (prescription.dosage = v)"
                        />
                      </v-col>
                      <v-col
                        v-if="$auth.check({'interpretations': ['*', 'edit']})"
                        cols="1"
                        class="d-flex align-center"
                      >
                        <v-btn
                          fab
                          x-small
                          color="primary"
                          class="mt-n3"
                          @click="drugIndex == 0 ? addDrug() : interpretation.drugs.splice(drugIndex, 1)"
                        >
                          <v-icon v-text="drugIndex == 0 ? 'mdi-plus' : 'mdi-minus'" />
                        </v-btn>
                      </v-col>
                    </v-row> -->
                  </template>
                  <v-row
                    v-else
                    class="pb-0"
                  >
                    <v-col>
                      <v-combobox
                        v-model.trim="interpretation.impression_duration"
                        label="Message"
                        :rules="[v => !!v || 'This field is required']"
                        class="py-0 my-0"
                        :items="[
                          'Treatment may not be indicated',
                          'Treatment may not be indicated as viruses are typically self-limiting',
                          'See below regarding treatment recommendations',
                          'Report cannot be generated'
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <template
                v-for="item in questions"
                class="mb-2"
              >
                <v-textarea
                  :key="`textarea-${item.field}`"
                  :readonly="!$auth.check({'interpretations': ['*', 'edit']})"
                  xv-model.trim="interpretation.instructions[item.field]"
                  :label="item.question"
                  :loading="loading.instructions[item.field]"
                  auto-grow
                  :counter="item.limit ? item.limit : 400"
                  maxlength="550"
                  :rows="item.rows ? item.rows : 4"
                  :value="interpretation.instructions[item.field]"
                  @change="v => (interpretation.instructions[item.field] = v)"
                >
                  <template #append>
                    <span style="width:30px" />
                  </template>
                  <template #append-outer>
                    <smart-phrases
                      :key="item.field"
                      :uuid="$route.params.uuid"
                      :field="item.field"
                      :question="item.question"
                      :organisms="detected_organisms"
                      :resistance-genes="detected_resistance"
                      :relevant-resistance="relevantResistance"
                      :diagnoses="lab_result.diagnoses"
                      :allergies="lab_result.allergies"
                      :pediatric="pediatric"
                      :threshold="smart_threshold"
                      :preload="lab_result && !lab_result.interpretation && !unclassified_data && !lab_result.negative"
                      :text="interpretation.instructions[item.field]"
                      :filter-gender="lab_result.sample_source == 'urine' ? lab_result.patient.gender : false"
                      :disabled="item.disabled"
                      :auto-populate="!unclassified_data"
                      class="pt-0 mx-n12"
                      @instruction-selected="updateInstruction(item.field, $event)"
                    />
                  </template>
                </v-textarea>
                <drug-alternatives
                  v-if="item.extra && item.extra == 'drug-alternatives'"
                  :key="`drug-alternatives-${item.field}-${$route.params.uuid}`"
                  :value.sync="interpretation.alternatives"
                  :uuid="$route.params.uuid"
                  :organisms="detected_organisms"
                  :resistance-genes="detected_resistance"
                  :relevant-resistance="relevantResistance"
                  :diagnoses="lab_result.diagnoses"
                  :allergies="lab_result.allergies"
                  :pediatric="pediatric"
                  :source="lab_result.sample_source"
                  :gender="lab_result.patient.gender"
                  :pregnant="lab_result.pregnant"
                  :formulary="lab_result.formulary"
                  :method="lab_result.method"
                />
              </template>
            </v-form>
          </v-card-text>
          <v-divider />
          <status-notes
            :status-text="['quality assurance', 'pending approval']"
            type="notes"
            :statuses="lab_result.statuses"
          />
          <v-card-text class="pb-0">
            <v-textarea
              v-model="interpretation.notes"
              label="Reviewer's Notes"
              rows="1"
              auto-grow
              class="mb-0"
              :value="interpretation.notes"
              @change="v => (interpretation.notes = v)"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-col>
              <flag-for-review
                :key="`flag:${lab_result.uuid}`"
                :uuid="lab_result.uuid"
                :load="lab_result.status"
                @flagged="(uuid) => {
                  $router.replace({
                    params: { uuid: uuid },
                  })
                  initializeInterpretation()
                  fetchLabResult()
                }"
              />
              <v-btn
                v-if="!lab_result.interpretation && lab_result.media && lab_result.media.length"
                :to="{ name:'Data Extraction', params: { uuid:lab_result.uuid } }"
                color="info"
                icon
                outlined
                title="Re-extract Data"
                class="mr-2"
              >
                <i class="fas fa-eye-dropper" />
              </v-btn>
              <v-btn
                v-if="lab_result.interpretation && !lab_result.interpretation.approved_at && $auth.check({'interpretations': ['*', 'edit']})"
                color="grey darken-1"
                icon
                outlined
                title="Reset Interpretation"
                class="mr-2"
                @click="initializeInterpretation"
              >
                <i class="fas fa-arrow-rotate-left" />
              </v-btn>
            </v-col>
            <v-col
              v-if="!unclassified_data && (lab_result.status != 'quality assurance')"
              class="shrink text-no-wrap"
            >
              <v-checkbox
                v-model="interpretation.needsQA"
                :disabled="lab_result.interpretation && lab_result.interpretation.approved_at != null"
                xreadonly="mandatory_qa"
                label="Additional QA"
                color="pink lighten-1"
              />
            </v-col>
            <v-col
              v-if="unclassified_data"
              class="shrink text-no-wrap"
            >
              <status-chip
                status="unclassified data"
              />
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="primary"
                :loading="loading.button"
                :disabled="disable_submit"
                @click="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-footer
      height="auto"
      class="pa-0"
      inset
      app
      elevation="4"
    >
      <treatment-table
        id="treatment-matrix"
        :key="lab_result.match_hash"
        :source="lab_result.sample_source"
        :organisms="detected_pathogens.length ? detected_pathogens : detected_organisms.filter(x => x.controversial)"
        :resistance="detected_resistance.map(x => x.resistance)"
        :allergies="lab_result.allergies"
        :pregnant="lab_result.pregnant"
        :pediatric="pediatric"
        :formulary="lab_result.formulary"
        width="100%"
        @resize="matrix_height = $event"
      />
    </v-footer>
  </v-container>
</template>
<script>
  export default {
    metaInfo () {
      return {
        title: 'Lab Result ' + this.lab_result.external_id + ' | Interpretation',
      }
    },
    components: {
      RecordLock: () => import('@/components/lab_result/RecordLock.vue'),
      // DrugPicker: () => import('@/components/drug/DrugPicker.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      DrugAlternatives: () => import('@/components/drug/DrugAlternatives.vue'),
      DrugRegimen: () => import('@/components/drug/DrugRegimen.vue'),
      FlagForReview: () => import('@/components/lab_result/FlagForReview.vue'),
      LabResult: () => import('@/components/lab_result/LabResult.vue'),
      MethodChip: () => import('@/components/MethodChip.vue'),
      SmartImpression: () => import('@/components/interpretation/SmartImpression.vue'),
      SmartPhrases: () => import('@/components/interpretation/SmartPhrases.vue'),
      StatusChip: () => import('@/components/StatusChip.vue'),
      StatusNotes: () => import('@/components/lab_result/StatusNotes.vue'),
      TreatmentTable: () => import('@/components/interpretation/TreatmentTable.vue'),
    },
    data: () => ({
      loading: {
        lab_result: false,
        button: false,
        drugs: {
          0: false,
        },
        instructions: false,
      },
      dialogs: {
        organism: false,
      },
      search: {
        drugs: [''],
      },
      valid: true,
      drugs: [],
      lab_result: {
        patient: {},
        organisms: [],
        resistance_genes: [],
      },
      form: {
        significant: [],
      },
      interpretation: {},
      tributeOptions: {
        values: [
          'abc', 'def',
        ],
      },
      matrix_height: 25,
    }),
    computed: {
      treatment_regimens () {
        let regimens = []
        if (this.interpretation.one_choice) {
          regimens.push(this.interpretation.one_choice)
        }
        if (this.interpretation.alternatives) {
          this.interpretation.alternatives.forEach(regimen => regimens.push(regimen))
        }
        return regimens
      },
      invalid_regimens () {
        let allergen_classes = this.lab_result?.allergies?.filter(x => x.group?.relevant).flatMap(x => x.group.drug_classes).flatMap(x => x.id) ?? []
        return this.treatment_regimens.map(regimen => {
          return regimen.regimens.filter(x => x.drug?.name).map(x => x.drug).map(drug => {
            let val = false
            if (drug.resistance) {
              val = drug.resistance.map(x => x.name).some(r => allergen_classes.includes(r))
            }
            if (allergen_classes.includes(drug.class_id)) {
              val = true
            }
            return val
          })
        })
      },
      detected_organisms () {
        return this.lab_result.organisms.filter(x => x.result.detected)
      },
      detected_pathogens () {
        return this.detected_organisms.filter(x => x.significant)
      },
      detected_resistance () {
        return this.lab_result.resistance_genes ? this.lab_result.resistance_genes.filter(x => x.result.detected && x.resistance) : []
      },
      patient_age () {
        return this.$moment(this.lab_result.created_at).diff(this.lab_result.patient.dob, 'years')
      },
      pediatric () {
        return this.lab_result.created_at && this.patient_age < 18
      },
      relevantResistance () {
        var detected_resistance = this.detected_resistance.map(x => x.resistance.name.en)
        var organisms = this.detected_pathogens.length ? this.detected_pathogens : this.detected_organisms.filter(x => x.controversial)
        return organisms.flatMap(x => x.relevant_resistances.map(x => x.name.en)).filter((v, i, a) => a.indexOf(v) === i).filter(r => detected_resistance.includes(r)).sort()
      },
      questions () {
        return [
          {
            question: this.$t('questions.alternatives'),
            field: 'alternatives',
            extra: 'drug-alternatives',
            rows: 2,
            limit: 300,
          },
          {
            question: this.$t('questions.one_choice_explained'),
            field: 'one_choice_explained',
            disabled: !this.$auth.check({'lab results': ['*', 'interpret']}),
          },
          {
            question: this.$t('questions.treated_when'),
            field: 'treated_when',
            disabled: !this.$auth.check({'lab results': ['*', 'interpret']}),
          },
          {
            question: this.$t('questions.considerations'),
            field: 'considerations',
            disabled: !this.$auth.check({'lab results': ['*', 'interpret']}),
          },
          {
            question: this.$t('questions.infection_control'),
            field: 'infection_control',
            disabled: !this.$auth.check({'lab results': ['*', 'interpret']}),
          },
        ]
      },
      unclassified_data () {
        return (this.lab_result.allergies && this.lab_result.allergies.filter(x => !x.group_id).length > 0) ||
          (this.lab_result.resistance_genes && this.detected_resistance.filter(x => !x.resistance).length > 0) ||
          (this.lab_result.organisms && this.detected_organisms.filter(x => x.new).length > 0)
      },
      smart_threshold () {
        return 0.9
        // var threshold
        // switch (this.lab_result.status) {
        //   case 'high confidence':
        //     threshold = 0.9
        //     break
        //   case 'new':
        //     threshold = 0.45
        //     break
        //   default:
        //     threshold = 0.7
        // }
        // return threshold
      },
      disable_submit () {
        return !this.$auth.check({'lab results': ['*', 'interpret']}) ||
          (this.lab_result.record_lock && this.lab_result.record_lock.user.uuid != this.$auth.user().uuid) ||
          (this.lab_result.interpretation && this.lab_result.interpretation.approved_at != null) ||
          (this.invalid_regimens.map(x => x.every(v => v) && this.interpretation.drugs_indicated).includes(true)) ||
          this.unclassified_data
      },
      mandatory_qa () {
        if (this.invalid_regimens.flatMap(x => x).includes(true) && this.lab_result.status != 'quality assurance') {
          this.$set(this.interpretation, 'needsQA', true)
          return true
        } else {
          return false
        }
      },
    },
    created () {
      this.initializeInterpretation()
      this.fetchLabResult()
    },
    methods: {
      submit () {
        if (this.warnings().length) {
          this.$dialog.confirm({
            title: 'Integrity Check',
            icon: 'fal fa-check',
            text: `<ul class="warnings"><li>${ this.warnings().join("</li><li>") }</li></ul>`,
            actions: {
              false: {
                text: 'Make changes',
                outlined: true,
              },
              true: {
                text: 'Confirmed',
                color: 'primary',
                elevation: 2,
                outlined: true,
              },
            },
          })
            .then((confirmed) => {
              if (confirmed) {
                this.submitInterpretation(this.$route.params.return)
              }
            })
        } else {
          this.submitInterpretation(this.$route.params.return)
        }
      },
      initializeInterpretation() {
        this.interpretation = {
          instructions: {},
          drugs_indicated: true,
          drugs: [
            {
              drug: {},
            },
          ],
          alternatives: [],
          one_choice: {
            indication_id: null,
            regimens: [{
              dosage: null,
              duration: null,
              drug: {},
              pivot: {
                connector: null,
              },
            }],
          },
        }
      },
      submitInterpretation (goBack) {
        if (this.$refs.interpretation.validate()) {
          this.loading.button = true
          this.axios.post('lab_results/' + this.$route.params.uuid + '/interpretation', this.interpretation)
            .then((res) => {
              if (!goBack && res.data.next) {
                this.$router.replace({
                  params: { uuid: res.data.next },
                })
                this.initializeInterpretation()
                this.fetchLabResult()
              } else if (goBack) {
                this.$router.push({ name: goBack, params: { uuid: res.data.next } })
              } else {
                this.$router.push({ name: 'Lab Results' })
              }
            })
            .catch(error => {
              if (error.response.status === 422) {
                Object.entries(error.response.data).forEach((error) => {
                  this.$toast.error(error)
                })
              } else {
                this.$toast.error(error)
              }
            })
            .finally(() => { this.loading.button = false })
        }
      },
      fetchLabResult () {
        this.loading.lab_result = true
        const promise = this.axios.get('lab_results/' + this.$route.params.uuid, {
          params: {
            lock: this.$auth.check({'lab results': ['*', 'interpret']}) && (!this.lab_result.interpretation || this.lab_result.interpretation.approved_at != null),
          },
        })

        return promise.then((response) => {
          if (response.data.interpretation) {
            response.data.interpretation.drugs_indicated = response.data.interpretation.drugs.length > 0 || response.data.interpretation.one_choice != undefined
            for (let i = 0; i < response.data.interpretation.drugs.length; ++i) {
              response.data.interpretation.drugs[i] = {
                drug: response.data.interpretation.drugs[i],
                dosage: response.data.interpretation.drugs[i].impression.dosage,
              }
            }
            response.data.interpretation.instructions = Object.keys(response.data.interpretation.instructions).length ? response.data.interpretation.instructions : {}
            this.interpretation = response.data.interpretation
            if (!this.interpretation.one_choice) {
              this.interpretation.one_choice = {
                indication_id: null,
                regimens: [{
                  dosage: null,
                  duration: null,
                  drug: {},
                  pivot: {
                    connector: null,
                  },
                }],
              }
            }
          }
          this.lab_result = response.data
          this.loading.lab_result = false
        }).catch(error => {
          this.$toast.error(error)
        })
      },
      defaultDosage (prescription) {
        if (prescription) {
          if (this.pediatric && prescription.drug.name.en && !prescription.dosage.includes('pediatric dosed') ) {
            return prescription.dosage = 'pediatric dosed'
          } else if (prescription.dosage) {
            return prescription.dosage
          } else if (prescription.drug && prescription.drug.dosages) {
            var dosage = prescription.drug.dosages.find(x => x.source == this.lab_result.sample_source)
            return dosage ? dosage.dosage : ''
          }
        }
      },
      addDrug () {
        this.interpretation.drugs.push({
          drug: {
            name: null,
          },
        })
        this.drugs.push(this.drugs[0])
      },
      updateImpression (impression) {
        this.$set(this.interpretation, 'impression_duration', impression.impression_duration)
        if (impression.oneChoice) {
          this.$set(this.interpretation, 'one_choice', impression.oneChoice)
        } else {
          this.$set(this.interpretation, 'drugs', impression.drugs.map(x => (
            { dosage: x.impression.dosage, drug: x }
          )))
        }
      },
      updateInstruction (key, instruction) {
        this.$set(this.interpretation.instructions, key, (typeof instruction.instruction === 'object' ? instruction.instruction.en : instruction.instruction))
      },
      warnings () {
        var warnings = []
        if (this.interpretation.instructions) {
          for (const key in this.interpretation.instructions) {
            if (!this.interpretation.instructions[key].endsWith('.')) {
              warnings.push(`<b>${this.questions.find(x => x.field == key).question}</b> may be incomplete.`)
            }
          }
        }
        if (this.lab_result.sample_source == 'unknown') {
          warnings.push(`The source of this lab result is unknown.`)
        }
        if (this.lab_result.pregnant) {
          warnings.push(`The patient has been reported to be pregnant. <h4 style="color:pink lighten-1">Avoid any incompatible drugs.</h4>.`)
        }
        if (this.pediatric) {
          warnings.push(`This patient is <b>${this.patient_age}</b> ${this.$options.filters.pluralize(this.patient_age, 'year', true)} old. <h4 style="color:orange">Ensure pediatric dosing.</h4>.`)
        }
        if (this.lab_result.allergies && this.lab_result.allergies.length) {
          warnings.push('Patient allergies were reported.')
        }
        if (this.detected_resistance.filter(x => x.resistance == null).length) {
          warnings.push('Unclassified resistance gene detected.')
        }
        if (this.treatment_regimens.length || (this.interpretation.drugs_indicated && this.interpretation.drugs.length)) {
          let drugs = []
          if (this.treatment_regimens.length) {
            drugs = this.treatment_regimens.flatMap(x => x.regimens.map(x => x.drug))
          } else if (this.interpretation.drugs_indicated && this.interpretation.drugs.length) {
            drugs = this.interpretation.drugs.map(x => x.drug)
          }
          drugs.forEach((drug) => {
            if (this.pediatric && drug.no_pediatric_use) {
              warnings.push(`<b>${drug.name.en}</b> is not recommended for pediatric use.`)
            }
            if (drug.resistance && drug.resistance.map(r => r.name.en).some(r => this.relevantResistance.includes(r))) {
              warnings.push(`Resistance was detected that may affect <b>${drug.name.en}</b>.`)
            }
            if (drug.ineffective_sources && drug.ineffective_sources.includes(this.lab_result.sample_source)) {
              warnings.push(`<b>${drug.name.en}</b> is not effective in ${this.lab_result.sample_source} infections.`)
            }
          })
        }
        return warnings
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
.v-dialog {
  position: absolute;
  right: 0;
}
.warnings li {
  margin-bottom: 2px;;
}
.warnings h4 {
  margin-bottom: 0;
}
</style>
